.wrapper {
    padding: 8px 0;
    width: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    overflow: hidden;
    background-color: #282b31;
}

.link {
    display: flex;
    padding: 4px 0;
    gap: 8px;
    flex-flow: column nowrap;
    align-content: center;
    height: auto;
    align-items: center;
}
.link span {
    color: #748398;
    font-size: 10px;
    width: 100%;
    text-align: center;
    display: block;
    font-weight: 500;
    text-transform: capitalize;
    white-space: nowrap;
}
.link svg {
    width: 24px;
    height: 24px;
}
.center {
    display: flex;
    justify-content: center;
}

.qrCodeButton {
    display: flex;
    overflow: hidden;
}
