.text {
    margin-bottom: 24px;
    text-align: justify;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}

.liItem {
    margin-bottom: 24px;
}

.ul {
    margin: 24px;
}

.ol li {
    list-style: disc;
}
