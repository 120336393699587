.subTitle2 {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 24px;
}

.text {
    text-align: justify;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 24px;
}

.list {
    margin-bottom: 24px;
}

.item {
    text-align: justify;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 20px;
}

.item:last-child {
    margin-bottom: 0;
}

.itemTitle {
    margin-bottom: 12px;
}
