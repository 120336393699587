.wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 32px;
    margin-top: 8px;
}

.container {
    width: 100%;
    max-width: 900px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    background: #282b31;
    border-radius: 50px;
    padding: 20px 40px;
}

.logo {
    margin-right: 28px;
}
.logo img {
    width: 38px;
    height: 36px;
}
.qrCodeButton {
    display: none;
}

@media screen and (max-width: 992px) {
    .wrapper {
        margin-bottom: 38px;
    }

    .logo {
        margin-right: 0;
        order: 2;
    }
    .logo img {
        width: 118px;
        height: 34px;
    }
    .userCard {
        order: 1;
    }

    .qrCodeButton {
        display: flex;
        order: 3;
    }

    .navbar {
        display: none;
    }
}

@media screen and (max-width: 576px) {
    .container {
        padding: 0;
        background: none;
    }

    .wrapper {
        margin-bottom: 20px;
    }
    .userCardMainPage {
        margin-left: 6.5px;
        margin-top: 8px;
    }
    .userCardMainPage button div {
        z-index: 2;
        background: #12161e;
        border-radius: 50%;
    }
    .qrCodeButtonMainPage {
        position: relative;
        z-index: 2;
    }
}
