.wrapper {
    position: fixed;
    left: 0;
    bottom: 0px;
    display: flex;
    justify-content: center;
    width: 100vw;
    filter: drop-shadow(0px -12px 20px rgba(0, 0, 0, 0.25));
    z-index: 10;
}
.content {
    justify-content: center;
    background: #12161e;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
}
