.wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.type {
    height: 28px;
    margin-right: 12px;
    text-transform: uppercase;
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
}
.get {
    background-color: #487bff;
}
.post {
    background-color: #00ce8c;
}
.put {
    background-color: #00ce8c;
}
.delete {
    background-color: #ff3939;
}
.url {
    font-weight: bold;
    cursor: default;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
}
.col {
    display: flex;
    align-items: center;
}
.desc {
    padding-right: 10px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
}
