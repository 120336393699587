.wrapper {
    gap: 50px;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.softnoteImages {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media screen and (max-width: 575px) {
    .wrapper {
        grid-template-columns: 1fr;
    }
}

.img {
    max-width: 410px;
    width: 100%;
}

.top {
    display: flex;
    justify-content: end;
    margin-bottom: 20px;
}

.download {
    cursor: pointer;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    gap: 8px;
    padding: 13px 24px;
    border-radius: 8px;
    text-decoration: none;
}

.buttonText {
    display: flex;
    height: auto;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    font-family: Rubik;
}

.title {
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    margin-bottom: 24px;
}

.text {
    width: 100%;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 24px;
}
