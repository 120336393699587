.item:first-child button::after {
    display: none;
}

.item:nth-child(3) button::after {
    display: none;
}

.item:nth-child(4) button::after {
    display: none;
}

.item:nth-child(5) button::after {
    display: none;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.title {
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 22px;
    padding: 2px !important;
}

.icon {
    margin-right: 10px;
}

.activeLink .title {
    color: #1890ff;
}
