html {
    height: 100%;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    color: #fff !important;
    background-color: #12161e !important;
}

#root {
    padding-bottom: 100px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.accordion-button {
    background: #282b31 !important;
    color: #fff !important;
    box-shadow: none !important;
}

.accordion-collapse {
    background: #282b31 !important;
    color: #fff !important;
    box-shadow: none !important;
}

.sidebar .accordion-button {
    background-color: #12161e !important;
}

.sidebar .accordion-collapse {
    background-color: #12161e !important;
}

.accordion {
    --bs-accordion-btn-icon: url('./icons/arrow-down.svg') !important;
    --bs-accordion-btn-active-icon: url('./icons/arrow-down.svg') !important;
    --bs-accordion-border-color: none !important;
    --bs-accordion-border-radius: 0 !important;
}

th,
td {
    background: #282b31 !important;
    color: #fff !important;
}

table tr td.td2:last-child {
    word-break: break-word !important;
}

.acordionItemWrap table {
    table-layout: fixed !important;
}

.acordionItemWrap table thead th:first-child {
    width: 89px !important;
}

.wrapper {
    display: grid;
    grid-template-columns: 20% 80%;
    gap: 20px;
}

.offcanvas {
    background-color: #12161e !important;
    color: #fff !important;
}

.btn-close {
    --bs-btn-close-bg: url('./icons/close.svg') !important;
}

@media screen and (max-width: 940px) {
    .wrapper {
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
        padding-top: 50px;
    }
}

@media screen and (max-width: 560px) {
    .accordion-button {
        padding: 6px 10px !important;
        font-size: 13px !important;
    }
}

h5 a {
    color: #fff !important;
    text-decoration: none !important;
    font-size: 18px !important;
    border-bottom: 1px solid transparent !important;
}

.h5 {
    font-size: 18px !important;
}

.sidebar {
    font-size: 18px !important;
}

.acordionItemWrap {
    margin-bottom: 30px;
}

.accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.accordion-item:first-of-type .accordion-button {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
}

.accordion-body {
    padding: 10px 20px !important;
}

.nav-link a {
    text-decoration: none !important;
}

.nav-link a.activeLink {
    display: inline-block;
}

.container {
    max-width: 1100px !important;
}

@media screen and (max-width: 640px) {
    .container {
        padding-right: 10px !important;
        padding-left: 10px !important;
    }
}

.title {
    color: var(--50, #fff);
    font-family: Rubik;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 34px;
    margin-top: 34px;
    text-align: center;
}

.accordion {
    --bs-accordion-btn-icon-width: 10px !important;
}

.sidebar {
    position: sticky;
    top: 0;
}

.modal-dialog {
    max-width: 80vw !important;
}

.modal-header {
    background-color: #12161e !important;
    margin-bottom: -2px !important;
}

@media screen and (max-width: 920px) {
    .modal {
        display: flex !important;
        align-items: center !important;
    }

    .modal-dialog {
        max-width: 95vw !important;
    }
}

.buttonBlue {
    background-image: linear-gradient(135deg, #487bff 0%, #1656ff 100%);
    color: #FFFFFF;
}

.buttonBlue:hover {
    background-image: linear-gradient(315deg, #2e68ff 0%, #1656ff 100%);
}

.buttonBlue:active {
    background: #1656ff;
}

.pageTitle {
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    margin-bottom: 24px;
}

.pageSubTitle {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    margin: 24px 0;
}
