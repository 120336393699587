.text {
    margin-bottom: 24px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

.smallText {
    text-align: justify;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}
