.wrapper {
    display: flex;
    gap: 8px;
    flex-flow: row nowrap;
}
.link {
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    gap: 6px;
    align-items: center;
    height: 36px;
}
.link span {
    color: #d4d4d4;
    font-weight: 500;
    font-size: 14px;
    text-transform: uppercase;
    white-space: nowrap;
}
.link svg {
    width: 24px;
    height: 24px;
}
